/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"www.fit-okna.cz"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-y01nlg --style3 --full" name={"uvod"} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/40398/6b86f71e46f943c990d6e37cb6ed3e5c_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/6b86f71e46f943c990d6e37cb6ed3e5c_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/40398/6b86f71e46f943c990d6e37cb6ed3e5c_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/6b86f71e46f943c990d6e37cb6ed3e5c_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/40398/6b86f71e46f943c990d6e37cb6ed3e5c_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/40398/6b86f71e46f943c990d6e37cb6ed3e5c_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/6b86f71e46f943c990d6e37cb6ed3e5c_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/6b86f71e46f943c990d6e37cb6ed3e5c_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--1 flex--center" anim={"2"} animS={"4"} style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="--left pb--02 pl--02 pr--02 pt--02" anim={null} animS={null} style={{"maxWidth":1200}}>
              
              <Title className="title-box title-box--shadow5 fs--102" content={"<span style='color: white'>Reference</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-1);\">Vaše spokojenost na prvním místě&nbsp;</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"0gwwp1x9jmco"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box ff--3 swpf--uppercase" content={"Prodomia s.r.o."}>
              </Title>

              <Text className="text-box fs--24" style={{"maxWidth":1966}} content={"Pro společnost PRODOMIA , s.r.o. spravujeme byty, bytová družstva a celá SVJ které mají v péči dle jejich potřeb a požadavků&nbsp;<br>www.prodomia.cz<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"reference"}>
          
          <ColumnWrap className="column__flex --left el--2 pb--20 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--43" content={"“Super spolupráce, dobrá cena a rychlé dodání.\nMoc děkuji”"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"— Petra W. Mladá Boleslav&nbsp;"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 --full mt--30 pb--20 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"2"} fullscreen={true}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--1 mt--30 pb--20 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--30 pb--30 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-10wwxmf --style3 --full" name={"uvod"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/7ccb5b1f17df41fe974495a85e4e59ad_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/7ccb5b1f17df41fe974495a85e4e59ad_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/7ccb5b1f17df41fe974495a85e4e59ad_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/7ccb5b1f17df41fe974495a85e4e59ad_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/7ccb5b1f17df41fe974495a85e4e59ad_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/7ccb5b1f17df41fe974495a85e4e59ad_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/7ccb5b1f17df41fe974495a85e4e59ad_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/7ccb5b1f17df41fe974495a85e4e59ad_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4" anim={"7"} animS={"4"}>
              
              <Title className="title-box fs--72" content={"<span style=\"color: var(--color-custom-1--15);\">Úvodní prohlídka a&nbsp;konzultace zdarma.</span>"}>
              </Title>

              <Button className="btn-box btn-box--cbtn2 btn-box--filling2 fs--20 swpf--uppercase mt--30" href={"/kontakt"} content={"Domluvit schůzku"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 pb--16 pt--10" style={{"maxWidth":1200}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--24 w--600 swpf--uppercase" content={"<span style=\"color: var(--color-custom-1);\">Kontakt&nbsp;<br><br>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;fit-okna@seznam.cz<br></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/"} content={"<span style='color: var(--color-dominant)'>Úvod"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/sluzby"} content={"<span style='color: var(--color-dominant)'>Služby"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/reference"} content={"<span style='color: var(--color-dominant)'>Reference"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/kontakt"} content={"<span style='color: var(--color-dominant)'>Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}